<template>
  <v-container
    class="container-full-size"
  >
    <v-card
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <v-col
        cols="12"
        style="display: flex; justify-content: space-between; align-items: center;"
      >
        <div style="font-size: 1.2rem;">
          {{ str['historic'] }}
        </div>
        <v-btn
          v-if="hasPermission('nutrition_edit')"
          color="success"
          class="mr-0"
          @click="openNutrition()"
        >
          {{ str['update_diet_supplementation'] }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="tableHeaders"
        :items="nutritions"
        :no-data-text="str['nutritions_empty']"
        :no-results-text="str['nutritions_empty']"
        class="elevation-1"
        @click:row="openNutritionTableRow"
        :loading="isLoading"
        :loading-text="str['processing']"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="!multiple">
            {{ item.id === nutritions[0].id ? str['active'] : '-' }}
          </span>
          <span
            v-if="multiple"
            v-bind:class="[statusDict[item.status] ? (statusDict[item.status].type + '--text') : '']"
          >
            {{ statusDict[item.status] ? statusDict[item.status].label : str['n_a'] }}
          </span>
          <v-icon
            v-if="multiple"
            style="margin-left: 3px; transform: translateY(-1px);"
            @click.stop="openEditStatus(item)"
            color="secondary"
            class="cursor-hover"
          >
            mdi-pencil-box
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-icon
              small
              color="error"
              @click.stop="deleteNutrition(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </div>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialogNutrition"
      persistent
      max-width="650px"
    >
      <v-card v-if="nutrition">
        <v-card-title>
          <span class="headline">
            {{ str['diet_and_supplementation'] }} {{ nutrition && nutrition.date ? '(' + nutrition.date + ')' : '' }}
          </span>
        </v-card-title>

        <div v-if="!useNutritionPdf">
          <v-col
            v-if="showNutritionLink && nutrition && nutrition.extra_data"
            cols="12"
          >
            <v-text-field
              v-model="nutrition.extra_data.url"
              name="input-7-1"
              :label="str['link_url']"
              class="purple-input"
              style="margin-top: 10px;"
              hide-details
            />
          </v-col>
          <v-col
            v-if="showNutritionLink && nutrition && nutrition.extra_data"
            cols="12"
          >
            <v-textarea
              v-model="nutrition.extra_data.description"
              :label="str['link_description']"
              rows="3"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="nutritionSupplementation"
              :label="str['supplementation']"
              rows="3"
              hide-details
              :readonly="!multiple && !nutrition.current"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="nutrition.current"
              color="success"
              @click="addNutritionNewItem()"
            >
              {{ str['add_day'] }}
            </v-btn>
          </v-col>
          <v-col
            v-if="!nutritionItems || (nutritionItems && !nutritionItems.length)"
            cols="12"
            class="text-center primary--text"
            style="min-height: 200px; padding-top: 40px;"
          >
            {{ str['empty_list'] }}
          </v-col>
          <v-col
            v-for="(nutritionItem, index) in nutritionItems"
            :key="index"
            cols="12"
          >
            <div class="nutrition-item-container">
              <div
                v-if="nutrition.current"
                @click="removeNutritionItem(index)"
                class="nutrition-item-remove-icon"
              >
                <i class="mdi mdi-trash-can-outline"></i>
              </div>
              <v-text-field
                v-model="nutritionItem.name"
                name="input-7-1"
                :label="str['name']"
                class="purple-input"
                style="margin-top: 10px;"
                hide-details
                :readonly="!multiple && !nutrition.current"
              />
              <v-textarea
                v-model="nutritionItem.description"
                :label="str['description']"
                rows="5"
                hide-details
                class="mt-3"
                :readonly="!multiple && !nutrition.current"
              />
            </div>
          </v-col>
        </div>

        <div v-if="useNutritionPdf">
          <v-col
            cols="12"
            style="padding-bottom: 0;"
          >
            <v-text-field
              v-model="nutrition.name"
              name="input-7-1"
              :label="str['name']"
              class="purple-input"
              :readonly="!multiple && !nutrition.current"
            />
          </v-col>
          <div style="padding: 20px; display: flex; justify-content: center; align-items: center;">
            <v-btn
              color="blue"
              class="mr-0"
            >
              {{ str['add_pdf'] }}
            </v-btn>
            <input
              id="input-pdf-value"
              type="file"
              accept="application/pdf,application/vnd.ms-excel"
              @change="loadNutritionPdf"
              style="opacity: 0; position: absolute; height: 45px; width: 160px;"
            />
          </div>
          <div style="padding: 0 0 20px;">
            <iframe
              v-if="nutrition && nutrition.description"
              v-bind:src="nutrition.description"
              style="width: 100%; height: 50vh;"
            />
          </div>
        </div>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogNutrition = null"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            v-if="!multiple && nutrition && nutrition.current"
            color="success"
            @click="saveNutrition(true)"
          >
            {{ str['save_with_historic'] }}
          </v-btn>
          <v-btn
            v-if="!multiple && nutrition && nutrition.current"
            color="success"
            @click="saveNutrition()"
          >
            {{ str['save_without_historic'] }}
          </v-btn>
          <v-btn
            v-if="multiple"
            color="success"
            @click="saveNutrition()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNewBaseNutrition"
      scrollable
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['diets_base'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <div style="padding: 10px;">
          <v-text-field
            v-model="baseNutritionsSearchValue"
            append-icon="mdi-magnify"
            :label="user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_calories ? str['search_by_calories'] : str['search']"
            single-line
            hide-details
          />
        </div>
        <v-card-text style="height: 300px; padding: 10px 0;">
          <div
            v-for="baseNutrition in baseNutritions"
            :key="baseNutrition.id"
            v-show="baseNutritionFiltered(baseNutrition)"
            @click="confirmBaseNutrition(baseNutrition)"
            class="box-select-item"
          >
            {{ baseNutrition.name }}{{ user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_calories ? (' (' + baseNutrition.calories + ' ' + str['calories'] + ')') : '' }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="dialogNewBaseNutrition = false"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmBaseNutrition()"
            style="margin-right: 0;"
          >
            {{ str['no_use_base'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="multiple"
      v-model="dialogStatus"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['status'] }}
          </span>
        </v-card-title>
        <v-card-text v-if="itemToEdit">
          <v-container>
            <v-row>
              <v-autocomplete
                v-bind:label="str['status']"
                item-text="label"
                item-value="value"
                v-model="itemToEdit.status"
                :items="statusList"
                :no-data-text="str['no_data']"
                :search-input.sync="itemToEdit.searchInput"
                @change="itemToEdit.searchInput=''"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="cancelEditStatus"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveEditStatus"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <client-feedback
      ref="feedback"
      :parent="parent"
      :client="client"
      :mountedCallback="clientFeedbackMounted"
    />
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'client-diets',
    props: ['parent', 'client'],
    components: {
      ClientFeedback: () => import('@/views/dashboard/components/client/Feedback'),
    },
    data() {
      const user = Utils.getUser()
      const tableHeaders = [
        { text: window.strings['id'], value: 'id' },
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['status'], value: 'status', align: 'center' },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center' },
      ]

      let showNutritionLink = false
      if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_link) {
        showNutritionLink = true
      }

      let useNutritionPdf = false
      if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.pdf) {
        useNutritionPdf = true
      }

      let multiple = false
      if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.multiple) {
        multiple = true
        tableHeaders.splice(1, 0, { text: window.strings['name'], value: 'name', align: 'center' })
      }

      return {
        str: window.strings,
        hasPermission: Utils.hasPermission,
        user: user,
        isLoading: false,
        showNutritionLink: showNutritionLink,
        useNutritionPdf: useNutritionPdf,
        multiple: multiple,
        tableHeaders: tableHeaders,
        feedbackRef: null,
        nutritionItems: [],
        nutritionSupplementation: '',
        dialogNewBaseNutrition: false,
        baseNutritions: [],
        dialogNutrition: false,
        nutrition: null,
        nutritions: [],
        baseNutritionsSearchValue: '',
        statusDict: {},
        statusList: [],
        dialogStatus: false,
        itemToEdit: null,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getPlanStatus()
      this.getNutritions()
    },
    methods: {
      getPlanStatus: function () {
        if (this.multiple) {
          const list = Utils.getPlanStatus()
          if (list && list.length) {
            const dict = {}
            list.forEach(function (item) {
              dict[item.value] = item
            })
            this.statusDict = dict
            this.statusList = list
          }
        }
      },
      getNutritions: function (callback) {
        const self = this
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false
        let filtered = true
        if (nutritionConfiguration && nutritionConfiguration.show_type) {
          filtered = false
        }
        this.isLoading = true
        Api.getNutritionPlans({
          id: this.client.dbId,
          filtered: filtered,
          fields: ['id', 'name', 'date', 'status', 'type']
        }, function (response) {
          self.isLoading = false
          if (response.success) {
            self.nutritions = response.data
          }
          if (callback) {
            callback()
          }
        })
      },
      openNutritionTableRow: function (item) {
        this.openNutrition(item)
      },
      openNutrition: function (item, useBase) {
        const self = this

        if (item) {
          this.$isLoading(true)
          Api.getNutritionPlan({
            client_id: useBase ? null : this.client.dbId,
            id: item.id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              open(response.data[0])
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          open(item)
        }

        function open(nutrition) {
          if (!useBase && ((nutrition && nutrition.id !== self.nutritions[0].id) || !self.hasPermission('nutrition_edit'))) {
            if (self.showNutritionLink && typeof nutrition.extra_data === 'string') {
              if (nutrition.extra_data) {
                nutrition.extra_data = JSON.parse(nutrition.extra_data)
              } else {
                nutrition.extra_data = {
                  url: '',
                  description: '',
                }
              }
            }
            self.nutrition = nutrition
          } else {
            self.dialogNewBaseNutrition = false
            const nutritionData = nutrition ? nutrition : (!self.multiple ? self.nutritions[0] : null)

            self.nutrition = {
              id: nutritionData ? nutritionData.id : null,
              name: nutritionData ? nutritionData.name : '',
              description: nutritionData ? nutritionData.description : '',
              date: nutritionData ? nutritionData.date : '',
              video_url: nutritionData ? nutritionData.video_url : '',
              calories: nutritionData ? nutritionData.calories : '',
              type: nutritionData ? nutritionData.type : '',
              current: !self.multiple || (self.multiple && nutritionData) ? true : null,
            }

            if (self.showNutritionLink) {
              const defaultNutrition = nutritionData ? nutritionData : null
              let extraData = {
                url: '',
                description: '',
              }
              if (defaultNutrition && defaultNutrition.extra_data) {
                if (typeof defaultNutrition.extra_data === 'string') {
                  extraData = JSON.parse(defaultNutrition.extra_data)
                } else {
                  extraData = defaultNutrition.extra_data
                }
              }
              self.nutrition.extra_data = extraData
            }
          }

          if (!self.useNutritionPdf) {
            if (self.nutrition.current &&
              self.user.configurations && self.user.configurations.menu_items &&
              self.user.configurations.menu_items.indexOf('diets_base')
            ) {
              return self.openBaseNutritions()
            }

            try {
              const descriptionParse = JSON.parse(self.nutrition.description)
              self.nutritionItems = descriptionParse.items
              self.nutritionSupplementation = descriptionParse.supplementation
            } catch (error) {
              self.nutritionItems = []
              self.nutritionSupplementation = ''
            }
          }

          if (self.useNutritionPdf && !nutrition) {
            return self.openBaseNutritions()
          }

          self.dialogNutrition = true
        }
      },
      openBaseNutritions: function () {
        const self = this
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

        let filtered = true
        if (nutritionConfiguration && nutritionConfiguration.show_type) {
          filtered = false
        }

        this.$isLoading(true)
        Api.getNutritionPlans({
          id: null,
          filtered: filtered,
          fields: ['id', 'calories', 'category', 'date', 'days_count', 'name', 'proposal', 'status', 'type']
        }, function (response) {
          self.$isLoading(false)

          let baseNutritions = []
          if (response.success) {
            baseNutritions = response.data
          }

          if (baseNutritions.length) {
            self.baseNutritions = baseNutritions
            self.baseNutritionsSearchValue = ''
            self.dialogNewBaseNutrition = true
          } else {
            self.confirmBaseNutrition()
          }
        })
      },
      baseNutritionFiltered: function (baseNutrition) {
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false
        const searchValue = this.baseNutritionsSearchValue.toLowerCase()

        if (!searchValue) {
          return true
        }

        if (nutritionConfiguration && nutritionConfiguration.show_calories) {
          if (baseNutrition.calories.toString().toLowerCase().indexOf(searchValue) > -1) {
            return true
          }
        } else {
          if (baseNutrition.name.toLowerCase().indexOf(searchValue) > -1) {
            return true
          }
        }

        return false
      },
      confirmBaseNutrition: function (item) {
        const self = this

        if (item && item.id) {
          this.$isLoading(true)
          Api.getNutritionPlan({
            id: item.id
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              confirm(response.data[0])
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          confirm(item)
        }

        function confirm(baseNutrition) {
          try {
            const nutrition = baseNutrition ? baseNutrition : self.nutrition

            if (self.useNutritionPdf) {
              self.nutrition.name = nutrition.name
              self.nutrition.description = nutrition.description
            } else {
              const descriptionParse = JSON.parse(nutrition.description)
              self.nutritionItems = descriptionParse.items
              self.nutritionSupplementation = descriptionParse.supplementation
            }

            if (self.showNutritionLink && nutrition.extra_data) {
              if (typeof nutrition.extra_data === 'string') {
                self.nutrition.extra_data = JSON.parse(nutrition.extra_data)
              } else {
                self.nutrition.extra_data = nutrition.extra_data
              }
            }
          } catch (error) {
            self.nutritionItems = []
            self.nutritionSupplementation = ''
          }

          self.dialogNewBaseNutrition = false
          self.dialogNutrition = true
        }
      },
      addNutritionNewItem: function () {
        this.nutritionItems.push({
          name: '',
          description: '',
        })
      },
      removeNutritionItem: function (index) {
        this.nutritionItems.splice(index, 1)
      },
      saveNutrition: function (saveHistory) {
        const self = this
        const data = this.getNutritionData()
        this.$isLoading(true)

        if (data.id && !saveHistory) {
          Api.editNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.feedbackRef.openFeedbackDialog()
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          delete data.id
          Api.newNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.feedbackRef.openFeedbackDialog()
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getNutritionData: function () {
        const nutrition = {
          client_id: this.client.dbId,
          name: this.nutrition.name,
          description: this.nutrition.description,
          type: 1,
        }
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

        if (nutritionConfiguration && nutritionConfiguration.show_video) {
          nutrition.video_url = this.nutrition.video_url
        }

        if (nutritionConfiguration && nutritionConfiguration.show_type) {
          nutrition.type = this.nutrition.type
        }

        if (nutritionConfiguration && nutritionConfiguration.show_calories) {
          nutrition.calories = this.nutrition.calories
        }

        if (this.nutrition && this.nutrition.id) {
          nutrition.id = this.nutrition.id
        }

        if (!this.useNutritionPdf) {
          nutrition.description = JSON.stringify({
            items: this.nutritionItems,
            supplementation: this.nutritionSupplementation,
          })
        }

        if (this.showNutritionLink && this.nutrition.extra_data) {
          if (typeof this.nutrition.extra_data === 'string') {
            nutrition.extra_data = this.nutrition.extra_data
          } else {
            nutrition.extra_data = JSON.stringify(this.nutrition.extra_data)
          }
        }

        if (this.multiple && (!this.nutrition || (this.nutrition && !this.nutrition.status))) {
          nutrition.status = 1
        }

        return nutrition
      },
      loadNutritionPdf(e) {
        const self = this
        if (!e.target.files.length) {
          return false
        }
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          self.nutrition.description = e.target.result
        }
      },
      deleteNutrition(nutrition) {
        const self = this
        this.$confirm(
          window.strings['do_you_want_delete_diet'] + (nutrition ? (' "' + nutrition.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteNutritionPlan({
            client_id: this.client.dbId,
            id: nutrition.id,
          }, function (response) {
            if (response.success) {
              self.getNutritions(function () {
                self.$isLoading(false)
              })
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      openEditStatus(item) {
        this.itemToEdit = {
          id: item.id,
          status: item.status,
        }
        this.dialogStatus = true
      },
      cancelEditStatus() {
        this.dialogStatus = false
        this.itemToEdit = null
      },
      saveEditStatus() {
        const self = this
        this.$isLoading(true)
        Api.editNutritionPlan({
          client_id: this.client.dbId,
          id: this.itemToEdit.id,
          status: this.itemToEdit.status
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.getNutritions()
            self.dialogStatus = false
            self.itemToEdit = null
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      clientFeedbackMounted() {
        this.feedbackRef = this.$refs.feedback
      },
    },
  }
</script>
